import React from 'react';
import Select from 'components/form/select';
import { withField } from 'components/form/with-field';
import { useFormikContext } from 'formik';
import SelectWrapper from 'components/headless-select';
export const PRODUCTS = [
  {
    value: 'A1601',
    label: 'A1601',
    img: 'https://www.axis.com/sites/axis/files/2020-05/a1601_top_no_lid_1806_1600px.png',
    mo: '24v'
  },
  {
    value: 'A1610',
    label: 'A1610'
  },
  {
    value: 'A1210',
    label: 'A1210'
  },
  {
    value: 'A1001',
    label: 'A1001',
    img: 'https://www.axis.com/sites/axis/files/2020-05/a1001_inside_hi_1600px.png',
    mo: '12v'
  }
];

export const DEVICES = [
  {
    value: 'A8207',
    label: 'A8207',
    description: 'Door Station',
    type: 'intercom'
  },
  {
    value: 'A8004',
    label: 'A8004',
    description: 'Door Station',
    type: 'intercom'
  },
  {
    value: 'A8105',
    label: 'A8105',
    description: 'Door Station',
    type: 'intercom'
  },
  {
    value: 'A9161',
    label: 'A9161',
    description: 'Relay Module',
    type: 'relay'
  },
  {
    value: 'A9188',
    label: 'A9188',
    description: 'Relay Module',
    type: 'relay'
  },

  {
    value: 'M3075',
    label: 'M3075',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'M3106',
    label: 'M3106',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'P3245',
    label: 'P3245',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'P1428',
    label: 'P1428',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'P1455',
    label: 'P1455',
    description: 'Camera',
    type: 'camera',
    lpr: true
  },
  {
    value: 'P3344',
    label: 'P3344',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'M1135',
    label: 'M1135',
    description: 'Camera',
    type: 'camera',
    lpr: true
  },
  {
    value: 'M3065',
    label: 'M3065',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'M3026',
    label: 'M3026',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'M3115',
    label: 'M3115',
    description: 'Camera',
    type: 'camera'
  },
  {
    value: 'C1410',
    label: 'C1410',
    description: 'Speaker',
    type: 'speaker'
  },
  {
    value: 'P3265',
    label: 'P3265',
    description: 'camera',
    type: 'camera'
  },
  {
    value: 'Q6075',
    label: 'Q6075',
    description: 'camera',
    type: 'camera'
  },
  {
    value: 'Q6125',
    label: 'Q6125',
    description: 'camera',
    type: 'camera'
  },
  {
    value: 'M5526',
    label: 'M5526',
    description: 'camera',
    type: 'camera'
  },
  {
    value: 'M3215',
    label: 'M3215',
    description: 'camera',
    type: 'camera'
  },
  {
    value: 'M3077',
    label: 'M3077',
    description: 'camera',
    type: 'camera'
  },
  {
    value: 'P1465',
    label: 'P1465',
    description: 'camera',
    type: 'camera',
    lpr: true
  },
  {
    value: 'M3125',
    label: 'M3125',
    description: 'camera',
    type: 'camera'
  }
];

const ProductSelect = (props) => {
  const options = props.device ? DEVICES : PRODUCTS;

  return (
    <SelectWrapper
      title="Product Model"
      placeholder="Search a model..."
      idx="value"
      label="label"
      simple
      isSearchable={true}
      isMulti={false}
      options={options.filter((opts) => opts.type == props.type)}
      //value={options.find((r) => r.value == values[name])}
      closeMenuOnSelect={true}
      onChange={(e) => {
        props.form?.setFieldValue(props.field?.name, e?.value ?? null);
      }}
      {...props}
    />
  );
};

export default ProductSelect;
